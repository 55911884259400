// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-client-comments-js": () => import("./../../../src/components/client-comments.js" /* webpackChunkName: "component---src-components-client-comments-js" */),
  "component---src-components-property-detail-print-js": () => import("./../../../src/components/propertyDetail/Print.js" /* webpackChunkName: "component---src-components-property-detail-print-js" */),
  "component---src-components-property-detail-property-detail-js": () => import("./../../../src/components/propertyDetail/propertyDetail.js" /* webpackChunkName: "component---src-components-property-detail-property-detail-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-advice-js": () => import("./../../../src/pages/legal-advice.js" /* webpackChunkName: "component---src-pages-legal-advice-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-property-sales-js": () => import("./../../../src/pages/property-sales.js" /* webpackChunkName: "component---src-pages-property-sales-js" */),
  "component---src-pages-selling-your-property-js": () => import("./../../../src/pages/selling-your-property.js" /* webpackChunkName: "component---src-pages-selling-your-property-js" */),
  "component---src-pages-service-buying-a-property-js": () => import("./../../../src/pages/service-buying-a-property.js" /* webpackChunkName: "component---src-pages-service-buying-a-property-js" */),
  "component---src-pages-service-renovation-your-property-js": () => import("./../../../src/pages/service-renovation-your-property.js" /* webpackChunkName: "component---src-pages-service-renovation-your-property-js" */),
  "component---src-pages-service-tax-administration-js": () => import("./../../../src/pages/service-tax-administration.js" /* webpackChunkName: "component---src-pages-service-tax-administration-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

